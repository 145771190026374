import React, { useCallback, useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setViewEngagementLoading } from '../../../actions/viewEngagementActions';

import { ViewerEngagementTemplate } from './ViewerEngagementTemplate';
import { getMediaQueryDetails, saveInspectScreenDetails } from '../../../actions/publishActions';
import { getDetails } from '../../../services/mediaDisplayService';
import { mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { showMessage } from '../../../actions/globalActions';

const ViewerEngagementSettings = ({
	mediaId,
	mediaDetails,
	reloadData,
}: ViewerEngagementTemplate.ViewerEngagementSettingsProps) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId } = useSelector((state: any) => state.session);

	const [closeIncomingMessage, setCloseIncomingMessage] = useState(mediaDetails?.custom?.closeIncomingMessage);
	const [hideEngagementModule, setHideEngagementModule] = useState(mediaDetails?.custom?.hideEngagementModule);

	const changeCustomValue = useCallback(
		(key: string, value: boolean) => {
			dispatch(setViewEngagementLoading(true));

			dispatch(getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias)).then((media: any) => {
				let a = getDetails(media, false);
				if (!a) {
					return;
				}

				let mediaDetail = a;

				mediaDetail.custom[key] = value;

				const body = {
					id: mediaDetail.id,
					asset: {
						id: mediaDetail.asset.id,
					},
					catalog: {},
					metadata: mediaDetail.metadata,
					tags: mediaDetail.tags,
					custom: mediaDetail.custom,
				};

				dispatch(saveInspectScreenDetails(defaultAccountId, mediaId, mediaNames.medias, body)).then(
					(data: any) => {
						dispatch(setViewEngagementLoading(false));
						dispatch(
							showMessage(
								t(
									data
										? 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_SETTING_UPDATE_SUCCESS'
										: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_SETTING_UPDATE_FAILED'
								),
								data ? messageTypes.success : messageTypes.error
							)
						);
						data && reloadData?.();
					}
				);
			});
		},
		[defaultAccountId, dispatch, mediaId, t, reloadData]
	);

	const onCloseMessageChange = useCallback(
		(_e: React.ChangeEvent<{}>, checked: boolean) => {
			setCloseIncomingMessage(checked);
			changeCustomValue('closeIncomingMessage', checked);
		},
		[changeCustomValue]
	);

	const onHideModuleChange = useCallback(
		(_e: React.ChangeEvent<{}>, checked: boolean) => {
			setHideEngagementModule(checked);
			changeCustomValue('hideEngagementModule', checked);
		},
		[changeCustomValue]
	);

	return (
		<ViewerEngagementTemplate.TabContentWrapper>
			<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
				<FormControlLabel
					className={`${ViewerEngagementTemplate.baseClassName}-settings__control`}
					label={
						<>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_CLOSE_MESSAGES')}
							<div className={`${ViewerEngagementTemplate.baseClassName}-settings__helper-text`}>
								{t(
									'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_CLOSE_MESSAGES_DESCRIPTION'
								)}
							</div>
						</>
					}
					labelPlacement="start"
					onChange={onCloseMessageChange}
					control={<Switch color="primary" checked={closeIncomingMessage} />}
					disabled={false}
				/>
			</div>

			<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
				<FormControlLabel
					className={`${ViewerEngagementTemplate.baseClassName}-settings__control`}
					label={
						<>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_HIDE')}
							<div className={`${ViewerEngagementTemplate.baseClassName}-settings__helper-text`}>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_HIDE_DESCRIPTION')}
							</div>
						</>
					}
					labelPlacement="start"
					onChange={onHideModuleChange}
					control={<Switch color="primary" checked={hideEngagementModule} />}
					disabled={false}
				/>
			</div>
		</ViewerEngagementTemplate.TabContentWrapper>
	);
};

export default ViewerEngagementSettings;
