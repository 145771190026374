import React from 'react';
import { WIDGET_TYPES } from '../../../../../../../../constants/scenarioConstant';
import Video from './contentChild/Video';
import Image from './contentChild/Image';
import Icon from './contentChild/Icon';
import Button from './contentChild/Button';
import Text from './contentChild/textWidget/Text';
import Hotspot from './contentChild/Hotspot';

import './Content.scss';

type getContentComponentByTypeProps = ContentProps & {
	type: string;
};

const getContentComponentByType = ({ type, ...otherProps }: getContentComponentByTypeProps) => {
	const {
		//common
		widgetTemplate,

		//video & image props
		uploadedFile,
		currentMediaDetail,
		uploadWidgetContent,
		onUploadFileClick,
		handleReturnedMedia,

		//image props
		isAtHoverSetting,
		setHoverSetting,

		//button props
		textHoverSetting,
		setTextHoverSetting,
		handleTextLabelChange,
		handleTextLabelFontChange,
		handleTextLabelFontSizeChange,
		handleTextLabelFontWeightChange,
		handleTextLabelColorChange,
		handleTextLabelBGColorChange,
		handleTextLabelHoverFontWeightChange,
		handleTextLabelHoverColorChange,
		handleTextLabelHoverBGColorChange,
		handleUpdateIconToButton,

		bgHoverSetting,
		setBGHoverSetting,
		handleBGColorChange,
		handleHoverBGColorChange,

		// icon props
		onShowIconLibraryPickerModal,

		//text props
		handleStylingAttributeChange,
	} = otherProps;

	switch (type) {
		case WIDGET_TYPES.WIDGET_TYPE_VIDEO:
			return (
				<Video
					widgetTemplate={widgetTemplate}
					uploadedFile={uploadedFile}
					currentMediaDetail={currentMediaDetail}
					uploadWidgetContent={uploadWidgetContent}
					onUploadFileClick={onUploadFileClick}
					handleReturnedMedia={handleReturnedMedia}
				/>
			);
		case WIDGET_TYPES.WIDGET_TYPE_IMAGE:
			return (
				<Image
					widgetTemplate={widgetTemplate}
					isAtHoverSetting={isAtHoverSetting}
					uploadedFile={uploadedFile}
					setHoverSetting={setHoverSetting}
					uploadWidgetContent={uploadWidgetContent}
					onUploadFileClick={onUploadFileClick}
				/>
			);
		case WIDGET_TYPES.WIDGET_TYPE_BUTTON:
			return (
				<Button
					widgetTemplate={widgetTemplate}
					textHoverSetting={textHoverSetting}
					setTextHoverSetting={setTextHoverSetting}
					handleTextLabelChange={handleTextLabelChange}
					handleTextLabelFontChange={handleTextLabelFontChange}
					handleTextLabelFontSizeChange={handleTextLabelFontSizeChange}
					handleTextLabelFontWeightChange={handleTextLabelFontWeightChange}
					handleTextLabelColorChange={handleTextLabelColorChange}
					handleTextLabelBGColorChange={handleTextLabelBGColorChange}
					handleTextLabelHoverFontWeightChange={handleTextLabelHoverFontWeightChange}
					handleTextLabelHoverColorChange={handleTextLabelHoverColorChange}
					handleTextLabelHoverBGColorChange={handleTextLabelHoverBGColorChange}
					handleUpdateIconToButton={handleUpdateIconToButton}
					onShowIconLibraryPickerModal={onShowIconLibraryPickerModal}
					handleBGColorChange={handleBGColorChange}
					handleHoverBGColorChange={handleHoverBGColorChange}
				/>
			);
		case WIDGET_TYPES.WIDGET_TYPE_ICON:
			return (
				<Icon
					widgetTemplate={widgetTemplate}
					isAtHoverSetting={isAtHoverSetting}
					setHoverSetting={setHoverSetting}
					handleTextLabelColorChange={handleTextLabelColorChange}
					handleTextLabelHoverColorChange={handleTextLabelHoverColorChange}
					onShowIconLibraryPickerModal={onShowIconLibraryPickerModal}
				/>
			);
		case WIDGET_TYPES.WIDGET_TYPE_TEXT:
			return (
				<Text
					widgetTemplate={widgetTemplate}
					isAtHoverSetting={isAtHoverSetting}
					setHoverSetting={setHoverSetting}
					handleStylingAttributeChange={handleStylingAttributeChange}
				/>
			);
		case WIDGET_TYPES.WIDGET_TYPE_HOTSPOT:
			return (
				<Hotspot
					widgetTemplate={widgetTemplate}
					bgHoverSetting={bgHoverSetting}
					setBGHoverSetting={setBGHoverSetting}
					handleBGColorChange={handleBGColorChange}
					handleHoverBGColorChange={handleHoverBGColorChange}
				/>
			);

		default:
			return <></>;
	}
};

type ContentProps = {
	//common props
	widgetTemplate: { type: string; settings: {}; style: {} };
	editingScene: {};
	widgetInBox: {};

	//video & image props
	uploadedFile: {};
	currentMediaDetail: any;
	uploadWidgetContent: () => {};
	onUploadFileClick: () => {};
	handleReturnedMedia: () => {};

	//image props
	isAtHoverSetting: boolean;
	setHoverSetting: (_value: boolean) => {};

	//button props
	textHoverSetting: boolean;
	setTextHoverSetting: (_value: boolean) => {};
	handleTextLabelChange: () => {};
	handleTextLabelFontChange: () => {};
	handleTextLabelFontSizeChange: () => {};
	handleTextLabelFontWeightChange: () => {};
	handleTextLabelColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelHoverFontWeightChange: () => {};
	handleTextLabelHoverColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelHoverBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleUpdateIconToButton: () => {};

	bgHoverSetting: boolean;
	setBGHoverSetting: (_value: boolean) => {};
	handleBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleHoverBGColorChange: (_event: {} | undefined, _color: {}) => {};

	//Icon props
	onShowIconLibraryPickerModal: () => {};

	//Text props
	handleStylingAttributeChange: () => {};
};

const Content = ({ widgetTemplate, ...others }: ContentProps) => {
	if (!widgetTemplate) {
		return null;
	}

	const { type } = widgetTemplate;
	return getContentComponentByType({ type, widgetTemplate, ...others });
};

export default Content;
