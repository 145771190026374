import React from 'react';
import { useTranslation } from 'react-i18next';
import { Archive, Close, Unarchive } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';

import { convertToLocalTime } from '../../../../services/timeStampService';
import Icons from '../../../../reusable/IconsStore/Icons';
import { joinClassNames } from '../../../../services/elementHelperService';

export namespace QuestionAndAnswerTemplate {
	export const qnaBaseClassName = 'qna-container';
	export const TAB = {
		review: 'review',
		approved: 'approved',
		answered: 'answered',
		archive: 'archive',
	};
	export const TabItems = [
		{
			label: 'COMMON_REVIEW',
			value: TAB.review,
		},
		{
			label: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_APPROVED',
			value: TAB.approved,
		},
		{
			label: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWERED',
			value: TAB.answered,
		},
		{
			label: <Archive />,
			value: TAB.archive,
		},
	];
	interface ReviewPanelProps {
		questions?: Question[];
		selectedTab?: string;
		onApproveQuestion?: (_question: Question) => void;
		onDiscardQuestion?: (_question: Question) => void;
		onUndiscardQuestion?: (_question: Question) => void;
		onAnswerButtonClick?: (_question: Question) => void;
	}

	export const ReviewPanel: React.FC<ReviewPanelProps> = ({
		questions = [],
		onApproveQuestion,
		onDiscardQuestion,
		onUndiscardQuestion,
		onAnswerButtonClick,
	}) => {
		const { t } = useTranslation();
		return (
			<>
				{questions.length <= 0 && (
					<div className={`${qnaBaseClassName}__question--message`}>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_QUESTION_MESSAGE')}
					</div>
				)}
				{questions.length > 0 &&
					questions.map((question) => (
						<QuestionPanel
							key={question.id}
							question={question}
							type={TAB.review}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
							onAnswerButtonClick={onAnswerButtonClick}
						/>
					))}
			</>
		);
	};

	export const ApprovedPanel: React.FC<ReviewPanelProps> = ({
		questions = [],
		onApproveQuestion,
		onDiscardQuestion,
		onUndiscardQuestion,
		onAnswerButtonClick,
	}) => {
		const { t } = useTranslation();
		return (
			<>
				{questions.length <= 0 && (
					<div className={`${qnaBaseClassName}__question--message`}>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_QUESTION_MESSAGE')}
					</div>
				)}
				{questions.length > 0 &&
					questions.map((question) => (
						<QuestionPanel
							key={question.id}
							question={question}
							type={TAB.approved}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
							onAnswerButtonClick={onAnswerButtonClick}
						/>
					))}
			</>
		);
	};

	export const AnsweredPanel: React.FC<ReviewPanelProps & { allQuestions?: Question[] }> = ({
		allQuestions = [],
		questions = [],
		onApproveQuestion,
		onDiscardQuestion,
		onUndiscardQuestion,
		onAnswerButtonClick,
	}) => {
		const { t } = useTranslation();
		return (
			<>
				{questions.length <= 0 && (
					<div className={`${qnaBaseClassName}__question--message`}>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_QUESTION_MESSAGE')}
					</div>
				)}
				{questions.length > 0 &&
					questions.map((question) => (
						<QuestionPanel
							key={question.id}
							answer={allQuestions.find((a) => a?.recipient?.replyQuestionId === question.id)}
							question={question}
							type={TAB.answered}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
							onAnswerButtonClick={onAnswerButtonClick}
						/>
					))}
			</>
		);
	};

	export const ArchivePanel: React.FC<ReviewPanelProps> = ({
		questions = [],
		onApproveQuestion,
		onDiscardQuestion,
		onUndiscardQuestion,
		onAnswerButtonClick,
	}) => {
		const { t } = useTranslation();
		return (
			<>
				{questions.length <= 0 && (
					<div className={`${qnaBaseClassName}__question--message`}>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_QUESTION_MESSAGE')}
					</div>
				)}
				{questions.length > 0 &&
					questions.map((question) => (
						<QuestionPanel
							key={question.id}
							question={question}
							type={TAB.archive}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
							onAnswerButtonClick={onAnswerButtonClick}
						/>
					))}
			</>
		);
	};
	export interface Question {
		id: string;
		question: string;
		sender: { name: string; admin?: boolean };
		recipient: { replyQuestionId?: string };
		created?: string;
		isAnswered?: boolean;
		discarded?: boolean;
		approved?: boolean;
		votes: number;
		qnaId?: string;
		reply?: string;
	}

	export interface QuestionProps {
		question: Question;
		answer?: Question;
		isAnswering?: boolean;
		type?: string;
		onApproveQuestion?: (_question: Question) => void;
		onDiscardQuestion?: (_question: Question) => void;
		onUndiscardQuestion?: (_question: Question) => void;
		onAnswerButtonClick?: (_question: Question) => void;
	}

	export const QuestionPanel: React.FC<QuestionProps> = ({
		answer,
		question,
		isAnswering,
		type,
		onApproveQuestion,
		onDiscardQuestion,
		onUndiscardQuestion,
		onAnswerButtonClick,
	}) => {
		const { t } = useTranslation();

		return (
			<div
				className={joinClassNames(
					`${qnaBaseClassName}__question`,
					isAnswering ? `${qnaBaseClassName}__question--answering` : ''
				)}
			>
				<div className={`${qnaBaseClassName}__question-title`}>
					<div>
						<span className={`${qnaBaseClassName}__question-sender`}>
							{isAnswering && (
								<>
									{`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWERING')}`}{' '}
									<b>{question?.sender?.name}</b>:
								</>
							)}
							{!isAnswering && <b>{question?.sender?.name}</b>}
						</span>

						{!isAnswering && (
							<span className={`${qnaBaseClassName}__question-time`}>
								{convertToLocalTime(question.created)}
							</span>
						)}
					</div>
					{isAnswering && (
						<IconButton
							className={`${qnaBaseClassName}__question-more-btn`}
							size="small"
							onClick={() => {}}
							title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STOP_ANSWER')}
						>
							<Close />
						</IconButton>
					)}
				</div>

				<div className={`${qnaBaseClassName}__question-content`}>{question.question}</div>
				{answer && (
					<div
						className={`${qnaBaseClassName}__question-content ${qnaBaseClassName}__question-content--answer`}
					>
						<b>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWER_BY')} {answer.sender?.name}:{' '}
						</b>
						<span>{answer?.reply}</span>
					</div>
				)}

				{!isAnswering && (
					<div className={`${qnaBaseClassName}__question-actions`}>
						{type === TAB.review && (
							<>
								<IconButton
									title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ARCHIVE')}
									className={`${qnaBaseClassName}__question--archive-btn`}
									onClick={() => onDiscardQuestion?.(question)}
								>
									<Archive />
								</IconButton>

								<Button
									variant="contained"
									color="primary"
									startIcon={<Icons.Recommended />}
									onClick={() => {
										onApproveQuestion?.(question);
									}}
								>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_APPROVE')}
								</Button>
							</>
						)}

						{type === TAB.approved && (
							<>
								<span className={`${qnaBaseClassName}__question-rank`}>
									<Icons.Recommended color="#126CFC" /> {question.votes}
								</span>

								<Button
									variant="contained"
									color="primary"
									disabled={question.isAnswered}
									onClick={() => onAnswerButtonClick?.(question)}
								>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWER')}
								</Button>
							</>
						)}

						{type === TAB.answered && (
							<>
								<span className={`${qnaBaseClassName}__question-rank`}>
									<Icons.Recommended color="#126CFC" /> {question.votes}
								</span>

								<IconButton
									title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ARCHIVE')}
									className={`${qnaBaseClassName}__question--archive-btn`}
									onClick={() => onDiscardQuestion?.(question)}
								>
									<Archive />
								</IconButton>
							</>
						)}

						{type === TAB.archive && (
							<>
								<span className={`${qnaBaseClassName}__question-rank`}>
									<Icons.Recommended color="#126CFC" /> {question.votes}
								</span>

								<Button
									variant="contained"
									color="primary"
									startIcon={<Unarchive />}
									onClick={() => onUndiscardQuestion?.(question)}
								>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_UNARCHIVE')}
								</Button>
							</>
						)}
					</div>
				)}
			</div>
		);
	};
}
