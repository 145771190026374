import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Close, DeleteOutline, ExpandLess, ExpandMore, MoreHoriz } from '@material-ui/icons';
import { Button, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import { showCreatePollFormAction } from '../../../../actions/pollActions';
import { generateId } from '../../../../services/stringHelperService';
import { joinClassNames } from '../../../../services/elementHelperService';
import Icons from '../../../../reusable/IconsStore/Icons';

import './PollsContainer.scss';

const pollBaseClassName = 'poll';
const pollContainerBaseClassName = `${pollBaseClassName}-container`;

export namespace PollsContainerTemplate {
	export interface PollsContainerProps {
		mediaId: string;
		onShowCreatePoll?: () => void;
	}

	export interface Option {
		id: number;
		value: number;
		text?: string;
		result?: number;
		percent?: string;
		winner?: boolean;
	}

	export interface PollResult {
		options: Option[];
		replies: number;
	}
	export interface PollData {
		id: string;
		text: string;
		options: Option[];
		published?: boolean;
		closed?: boolean;
		replies?: number;
		result?: PollResult;
	}

	export interface PollSectionProps {
		title?: React.ReactNode;
		collapsible?: boolean;
		polls?: PollData[];
		pollResult?: PollResult;
		noPollMessage?: string;
		// eslint-disable-next-line no-unused-vars
		onEndPollClick?: (event: React.MouseEvent<HTMLButtonElement>, poll: PollData, key: string) => void;
		// eslint-disable-next-line no-unused-vars
		onDeletePoll?: (pollId: string) => void;
		// eslint-disable-next-line no-unused-vars
		onActionClick?: (event: React.MouseEvent<HTMLButtonElement>, poll: PollData) => void;
	}

	export const PollSection: React.FC<PollSectionProps> = ({
		polls,
		title,
		collapsible,
		noPollMessage,
		onEndPollClick,
		onActionClick,
		onDeletePoll,
		pollResult,
	}) => {
		const { t } = useTranslation();
		const [openedPoll, setOpenedPoll] = useState<string>(polls?.[0]?.id ?? '');
		const [isExpanded, setIsExpanded] = useState<boolean>(true);

		const togglePollsPanel = useCallback(() => setIsExpanded((prev) => !prev), []);

		return (
			<>
				<div
					className={joinClassNames(
						`${pollContainerBaseClassName}__header`,
						collapsible ? `${pollContainerBaseClassName}__header--collapsible` : ''
					)}
				>
					{title}
					{collapsible && (
						<IconButton
							onClick={collapsible ? togglePollsPanel : undefined}
							size="small"
							style={{ width: '30px', height: '30px' }}
						>
							{isExpanded ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
					)}
				</div>
				{isExpanded && (
					<>
						{(!polls || polls.length === 0) && (
							<div className={`${pollContainerBaseClassName}__message`}>
								{noPollMessage ?? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_NOTHING')}
							</div>
						)}

						{polls && polls.length > 0 && (
							<div className={`${pollContainerBaseClassName}__polls`}>
								{polls.map((poll, index) => (
									<Poll
										poll={poll}
										key={index}
										isExpanded={openedPoll === poll.id}
										onSelectPoll={() => setOpenedPoll(openedPoll === poll.id ? '' : poll.id)}
										onEndPollClick={onEndPollClick}
										onActionClick={onActionClick}
										onDeletePoll={onDeletePoll}
										pollResult={pollResult}
									/>
								))}
							</div>
						)}
					</>
				)}
			</>
		);
	};

	export interface PollProps {
		poll: PollData;
		isExpanded?: boolean;
		pollResult?: PollResult;
		showPollQueue?: boolean;
		// eslint-disable-next-line no-unused-vars
		onEndPollClick?: (event: React.MouseEvent<HTMLButtonElement>, poll: PollData, key: string) => void;
		onSelectPoll?: () => void;
		// eslint-disable-next-line no-unused-vars
		onDeletePoll?: (pollId: string) => void;
		// eslint-disable-next-line no-unused-vars
		onActionClick?: (event: React.MouseEvent<HTMLButtonElement>, poll: PollData) => void;
	}

	export const Poll: React.FC<PollProps> = ({
		poll,
		isExpanded,
		onSelectPoll,
		onDeletePoll,
		onActionClick,
		onEndPollClick,
		pollResult,
	}) => {
		const { t } = useTranslation();
		const actionButtonRef = useRef<HTMLButtonElement | null>(null);
		const [openActionMenu, setOpenActionMenu] = useState<boolean>(false);
		const [showEndPollOption, setShowEndPollOption] = useState<boolean>(false);
		const [endPollOption, setEndPollOption] = useState<string>('publish-result');

		const options = useMemo(() => {
			const results = pollResult ?? poll.result;
			if (!results) {
				return poll?.options ?? [];
			}

			return getOptionsWithResult(poll.options, results);
		}, [poll, pollResult]);

		const onActionButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			setOpenActionMenu((prev) => !prev);
		}, []);

		const onPollActionButtonClick = useCallback(
			(e: React.MouseEvent<HTMLButtonElement>) => {
				if (!poll.published) {
					onActionClick?.(e, poll);
				} else if (poll.result && poll.published) {
					onEndPollClick?.(e, poll, 'unpublish');
				} else {
					setShowEndPollOption(true);
				}
			},
			[onActionClick, onEndPollClick, poll]
		);

		return (
			<>
				{!showEndPollOption && (
					<div key={poll.id} className={`${pollBaseClassName}__queue-item`}>
						<div className={`${pollBaseClassName}__queue-item-title`} onClick={onSelectPoll}>
							<div>
								<Icons.Polls />
								<span>{poll.text}</span>
							</div>

							<IconButton onClick={onActionButtonClick} ref={(ref) => (actionButtonRef.current = ref)}>
								<MoreHoriz />
							</IconButton>
						</div>

						{isExpanded &&
							options.map((option, index) => (
								<div
									className={joinClassNames([
										`${pollBaseClassName}__option`,
										pollResult ?? poll.result ? `${pollBaseClassName}__option--result` : '',
									])}
									key={option.id ?? index}
									data-text={option.text ?? 'Option'}
									data-percent={option.percent}
								>
									<div
										className={joinClassNames([
											`${pollBaseClassName}__option--text`,
											option.winner ? `${pollBaseClassName}__option--winner` : '',
										])}
										style={{ width: option.percent }}
									/>
								</div>
							))}

						{isExpanded && (
							<div className={`${pollBaseClassName}__actions`}>
								<Button
									variant="contained"
									style={
										!poll.published
											? { backgroundColor: '#126cfc' }
											: { backgroundColor: '#FD5E6B' }
									}
									onClick={onPollActionButtonClick}
								>
									{poll.result && poll.published
										? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UN_PUBLISH')
										: poll.published
										? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_END')
										: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH')}
								</Button>
							</div>
						)}
					</div>
				)}

				{showEndPollOption && (
					<div className={`${pollBaseClassName}__queue-item ${pollBaseClassName}__wrapper--endPollOptions`}>
						<div className={`${pollBaseClassName}__wrapper--endPollOptions-header`}>
							<span>
								<b>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_END')}</b>
							</span>
							<span>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_END_POLL_OPTIONS_HEADER')}
							</span>
						</div>
						<IconButton
							onClick={() => setShowEndPollOption(false)}
							style={{ position: 'absolute', right: '0', top: '0' }}
						>
							<Close />
						</IconButton>
						<FormControl fullWidth style={{ padding: '5%' }}>
							<RadioGroup
								style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '7px' }}
								defaultValue="responsive"
								value={endPollOption}
								onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) =>
									setEndPollOption(value)
								}
							>
								<FormControlLabel
									style={{
										height: '52px',
										padding: '0px 24px',
										margin: '0px',
										borderTopLeftRadius: '7px',
										borderTopRightRadius: '7px',
										backgroundColor: endPollOption === 'publish-result' ? '#2a2e38' : '',
									}}
									control={
										<Radio
											style={
												endPollOption === 'publish-result'
													? { backgroundColor: '#2a2e38' }
													: undefined
											}
										/>
									}
									label={t(
										'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH_RESULT_OPTION'
									)}
									value="publish-result"
								/>
								<FormControlLabel
									style={{
										height: '52px',
										padding: '0px 24px',
										margin: '0px',
										borderBottomLeftRadius: '7px',
										borderBottomRightRadius: '7px',
										backgroundColor: endPollOption !== 'publish-result' ? '#2a2e38' : '',
									}}
									control={
										<Radio
											style={
												endPollOption !== 'publish-result'
													? { backgroundColor: '#2a2e38' }
													: undefined
											}
										/>
									}
									label={t(
										'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_DO_NOT_PUBLISH_RESULT_OPTION'
									)}
									value="unpublish"
								/>
							</RadioGroup>
						</FormControl>

						<div className={`${pollBaseClassName}__actions`}>
							<Button
								variant="contained"
								onClick={(e) => {
									setShowEndPollOption(false);
									onEndPollClick?.(e, poll, endPollOption);
								}}
								style={{ backgroundColor: '#FD5E6B' }}
							>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_END')}
							</Button>
						</div>
					</div>
				)}

				<Menu
					open={openActionMenu}
					anchorEl={actionButtonRef.current}
					onClose={() => setOpenActionMenu(false)}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				>
					<MenuItem
						onClick={() => {
							onDeletePoll?.(poll.id);
							setOpenActionMenu(false);
						}}
					>
						<DeleteOutline />
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_DELETE_FROM_LIST')}
					</MenuItem>
				</Menu>
			</>
		);
	};

	const defaultOptions: Option[] = [
		{
			text: 'Yes',
			id: 1,
			value: 2,
		},
		{
			text: 'No',
			id: 2,
			value: 2,
		},
	];

	export interface CreatePollFormProps {
		// eslint-disable-next-line no-unused-vars
		createNewPoll?: (poll: PollData, shouldPublish?: boolean) => void;
	}

	export const CreatePollForm: React.FC<CreatePollFormProps> = ({ createNewPoll }) => {
		const dispatch = useDispatch();
		const { t } = useTranslation();

		const [options, setOptions] = useState<Option[]>(defaultOptions);
		const [questionText, setQuestionText] = useState('');

		const onOptionTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, option: any) => {
			const text = event.target.value.substr(0, 140);
			setOptions((prevOptions) => prevOptions.map((o) => (o.id === option.id ? { ...o, text } : o)));
		}, []);

		const onQuestionTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
			setQuestionText(event.target.value);
		}, []);

		const hidePollCreateForm = useCallback(() => {
			dispatch(showCreatePollFormAction(false));
		}, [dispatch]);

		const addMoreOption = useCallback(() => {
			setOptions((prevOptions) => [
				...prevOptions,
				{ text: ``, id: prevOptions.length + 1, value: prevOptions.length + 1 },
			]);
		}, []);

		const removeOption = useCallback((option: any) => {
			setOptions((prevOptions) => prevOptions.filter((o) => o.id !== option.id));
		}, []);

		const setFormToDefault = useCallback(() => {
			setQuestionText('');
			setOptions(defaultOptions);
			hidePollCreateForm();
		}, [setQuestionText, setOptions, hidePollCreateForm]);

		const onCreatePoll = useCallback(
			(shouldPublishPoll = false) => {
				const pollData: PollData = {
					id: generateId(),
					text: questionText,
					options: options,
				};
				createNewPoll?.(pollData, shouldPublishPoll);
				setFormToDefault();
			},
			[questionText, options, createNewPoll, setFormToDefault]
		);

		return (
			<div className={`${pollBaseClassName}__form ${pollBaseClassName}__wrapper`}>
				<div className={`${pollBaseClassName}__header`}>
					<div className={`${pollBaseClassName}__header-title`}>
						<Icons.Polls color="#0c306c" />
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CREATE_POLL')}
					</div>
					<IconButton onClick={hidePollCreateForm} style={{ borderRadius: '4px' }}>
						<Close />
					</IconButton>
				</div>
				<div className={`${pollBaseClassName}__question`}>
					<TextField
						label={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_FORM_QUESTION_LABEL')}
						variant="standard"
						fullWidth
						className={`${pollBaseClassName}__form--input ${pollBaseClassName}__question-text`}
						onChange={onQuestionTextChange}
						value={questionText}
						helperText={`${questionText.length} ${t(
							'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_LENGTH_140_CHARS'
						)}`}
					/>
				</div>
				{options.map((option, index) => (
					<div key={index} className={`${pollBaseClassName}__option--textField`}>
						<TextField
							className={`${pollBaseClassName}__form--input`}
							key={option.id}
							placeholder={t('COMMON_OPTION')}
							value={
								option.text === 'Yes'
									? t('COMMON_YES')
									: option.text === 'No'
									? t('COMMON_NO')
									: option.text
							}
							variant="outlined"
							fullWidth
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => onOptionTextChange(event, option)}
							helperText={`${(option.text ?? '').length} ${t(
								'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_LENGTH_140_CHARS'
							)}`}
						/>

						{index > 1 && (
							<div className={`${pollBaseClassName}__option--textField-action`}>
								<IconButton onClick={() => removeOption(option)}>
									<Close />
								</IconButton>
							</div>
						)}
					</div>
				))}

				<div className={`${pollBaseClassName}__option ${pollBaseClassName}__option--add`}>
					<Button
						variant="outlined"
						onClick={addMoreOption}
						className={`${pollBaseClassName}__form--secondary-btn`}
						style={{ color: '#126CFC', borderColor: '#126CFC' }}
					>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_ADD_OPTION')}
					</Button>
				</div>

				<div className={`${pollBaseClassName}__actions`}>
					<Button
						variant="outlined"
						onClick={() => onCreatePoll?.(false)}
						className={`${pollBaseClassName}__form--secondary-btn`}
						style={
							!questionText || options.filter((o) => o.text).length < 2
								? {}
								: { color: '#126CFC', borderColor: '#126CFC' }
						}
						disabled={!questionText || options.filter((o) => o.text).length < 2}
					>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_QUEUE_BTN')}
					</Button>
					<Button
						variant="contained"
						onClick={() => onCreatePoll?.(true)}
						className={'defaultActionBtn squireBtn'}
						disabled={!questionText || options.filter((o) => o.text).length < 2}
						title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_ONE_ACTIVE_AT_A_TIME')}
					>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_GO_LIVE_WITH_POLL_BTN')}
					</Button>
				</div>
			</div>
		);
	};

	export const getOptionsWithResult = (options: Option[], results: PollResult) => {
		const finalResults = (options ?? []).map((r) => {
			const option = (results?.options ?? []).find((o) => r.id === o.id);
			return { ...r, ...option };
		});

		const winner = finalResults.sort((a, b) => (b.result ?? 0) - (a.result ?? 0));
		return finalResults
			.map((option) => {
				const percent = Math.floor(!option.result ? 0 : (option.result / (results?.replies ?? 1)) * 100);

				return {
					...option,
					percent: `${percent}%`,
					winner: winner[0].id === option.id,
				};
			})
			.filter(Boolean);
	};
}
